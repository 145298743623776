<template>
<div class="flex w-full h-screen justify-center items-center text-gray-700">
    <el-empty description="Vous n'avez pas vérifier votre adresse email, veuillez la vérifier avant d'accéder à votre compte.">
        <button @click="logout" class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
            Déconnexion
        </button>
    </el-empty>
</div>
 
</template>
<script>
export default {
    methods: {
        logout() {
        this.$store.dispatch("logoutAction").then(() => {
        this.$router.push("/login");
        this.$store.commit('set_user', null);
      }).catch(() => {
      });
      
    },
    }
}
</script>
